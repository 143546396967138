
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import Status from '@/components/Status/index.vue'
import BargeService from '@/views/vessel/services/barge-service'
import String from '@/mixins/string-mixins'
import UomJobs from '@/data/uom-jobs.json'
  @Component({
    name: 'Barge',
    components: {
      Status
    }
  })
export default class Barge extends Vue {
    protected isEdit = false
    protected uoms = UomJobs
    protected barges: any = []
    protected bargeId: any = null

    protected barge: any = {
      id: null,
      name: null,
      imo_no: null,
      length_overall: null,
      uom: null,
      capacity: null,
      uos: null,
      yom: null,
      class: null,
      flag: null,
      other: null,
      status: null,
      created_user: null,
      modified_user: null
    }

    created () {
      this.boot()
    }

    protected boot () {
      this.populateBarges()
    }

    protected async populateBarges () {
      const response = await BargeService.getBarges()
      response.data.forEach((barge) => {
        this.barges.push({
          value: {
            id: barge.id,
            name: barge.name
          },
          text: barge.name
        })
      })
    }

    protected async populateBargeData (barge) {
      const response = await BargeService.getBarges()
    }

    private async createVesselCode () {
      if (this.barge.name === '' || this.barge.yom.length < 4) {
        this.barge.code = ''
        return null
      }
      const runningNumber = await BargeService.getBargeCode()
      this.barge.code = `${String.strByWords(this.barge.name)}/${this.barge.yom}/${runningNumber.data}`
    }

    private save () {
      if (this.isEdit) {
        this.update()
      } else {
        this.create()
      }
    }

    private create () {
      const barge = this.barge
      barge.created_user = AuthModule.user.fullname
      barge.modified_user = AuthModule.user.fullname
      BargeService.create(barge).then((response: any) => {
        ToastModule.message(response.data.message)
        this.cancel()
      }).catch(error => {
        ToastModule.message(error.response.data.message)
        console.log(error)
      })
    }

    private async edit (id: number) {
      this.isEdit = true
      const response = await BargeService.getBargeData(id)
      this.barge = response.data
    }

    private update () {
      const barge = this.barge
      barge.modified_user = AuthModule.user.fullname
      BargeService.update(barge).then((response: any) => {
        ToastModule.message(response.data.message)
        this.cancel()
      }).catch(error => {
        ToastModule.message(error.response.data.message)
        console.log(error)
      })
    }

    private cancel () {
      this.isEdit = false
      this.reset(this.barge)
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

